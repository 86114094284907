<template>
  <v-container>
    <div class="ml-2">
      <PageTitle text="Lube Sales" class="font" />
    </div>
    <div class="buttonText ml-1 d-flex flex-row flex-grow-1" id="result">
      <div class="d-flex flex-row flex-grow-1">
        <div class="buttonText ml-1" id="result">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            :nudge-left="10"
            offset-y
            min-width="auto"
            class="ft font-weight-medium  "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-ripple="{ class: 'primary--text' }"
                color="#04a9f3"
                rounded
                fab
                icon
                v-bind="attrs"
                v-on="on"
                small
                class="font ml-2 mt-3 white--text"
                style="font-size: 12px"
              >
                <i class="material-icons-outlined primary--text">event</i>
              </v-btn>
            </template>
            <v-date-picker
              :max="today | timestamp('YYYY-MM-DD')"
              class="font font-weight-medium  "
              v-model="date"
              event-color="primary"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <div
          class="black--text pl-5 absolute font font-weight-medium"
          style="margin-top: 22px;"
        >
          Daily Sales -
          <v-chip
            label
            small
            color="primary"
            class="white--text font-weight-bold mt-n1"
            >{{ date ? date : today }}</v-chip
          >
        </div>
      </div>
      <div class="d-flex flex-row mt-3 mr-5 animate__animated animate__fadeIn">
        <v-alert type="success" dense>
          <div class="font font-weight-medium text-uppercase font-size-sm">
            Total Lube Sales -
            <span class="font font-weight-bold font-size-md">{{
              sum(lineItems, "amount")
                | currencyFormat(
                  currency && currency.length > 0 ? currency[0].code : "GHS"
                )
            }}</span>
          </div>
        </v-alert>
      </div>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <v-alert
            dense
            v-if="hasSalesBeenRecorded"
            type="error"
            class="font font-size-sm font-weight-medium"
            >Sales for {{ date }} have been recorded
          </v-alert>
          <v-card
            class="font box-shadow-light pt-5 pb-4 animate__animated animate__fadeIn"
            style="border-radius: 8px;"
          >
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                @submit.prevent="handleSubmit(recordDailySales)"
                class="px-3"
              >
                <v-col>
                  <v-row>
                    <div class="d-flex flex-column flex-fill">
                      <div
                        class="d-flex flex-column flex-fill pa-4 ma-1 "
                        style="background-color: rgb(244, 245, 247); border-radius: 6px;"
                      >
                        <SkeletonPreloader
                          v-if="isPageLoading"
                          class="d-flex flex-column "
                          type="list-item-two-line"
                          :count="6"
                        />
                        <template v-else>
                          <v-alert
                            dense
                            v-if="isStockNotTallied"
                            type="error"
                            class="font font-size-sm font-weight-medium"
                            >Some of the quantity sold per day exceeds the total
                            stock quantity available. Kindly revise.
                          </v-alert>

                          <v-expansion-panels flat>
                            <v-expansion-panel
                              v-for="(row, index) in lineItems"
                              :key="index"
                              class="box-shadow-light"
                            >
                              <v-expansion-panel-header>
                                <div
                                  class="d-flex flex-row flex-grow-1 justify-space-between"
                                >
                                  <span class="font-weight-bold">
                                    Product Name :
                                    <v-chip
                                      small
                                      label
                                      color="primary"
                                      class="mr-2 "
                                      ><span>{{
                                        row.productName
                                      }}</span></v-chip
                                    >
                                  </span>
                                  <span
                                    v-if="!hasSalesBeenRecorded"
                                    class=" font font-weight-bold font-size-sm"
                                  >
                                    Stock Quantity:
                                    <v-chip
                                      small
                                      label
                                      color="primary"
                                      class="font font-weight-bold font-size-md"
                                      >{{ row.currentStockQuantity }}</v-chip
                                    >
                                  </span>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div class="d-flex flex-row flex-grow-1">
                                  <ValidationProvider
                                    tag="div"
                                    class="d-flex flex-row flex-grow-1 ma-2"
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <v-autocomplete
                                      :items="products"
                                      v-model="row.productId"
                                      item-text="name"
                                      readonly
                                      item-value="id"
                                      prepend-inner-icon="category"
                                      :error-messages="errors[0]"
                                      outlined
                                      label="Choose A Product"
                                      hint="Select from a list of product "
                                      class="font font-weight-medium text-sm"
                                    />
                                  </ValidationProvider>
                                  <ValidationProvider
                                    tag="div"
                                    rules="required|numeric"
                                    v-slot="{ errors }"
                                    class="d-flex flex-row flex-grow-1 ma-2"
                                  >
                                    <v-text-field
                                      outlined
                                      v-model="row.quantitySold"
                                      prepend-inner-icon="all_inclusive"
                                      :readonly="hasSalesBeenRecorded"
                                      label="Enter Quantity Sold"
                                      :error-messages="
                                        errors[0] ||
                                        row.currentStockQuantity < 0
                                          ? 'Quantity sold greater than stock qty'
                                          : ''
                                      "
                                      class="font font-weight-medium text-sm"
                                    />
                                  </ValidationProvider>
                                  <ValidationProvider
                                    tag="div"
                                    rules="required"
                                    v-slot="{ errors }"
                                    class="d-flex flex-row flex-grow-1 ma-2"
                                  >
                                    <v-text-field
                                      outlined
                                      v-model="row.amount"
                                      :prefix="
                                        currency && currency.length > 0
                                          ? currency[0].symbol
                                          : 'GHS'
                                      "
                                      readonly
                                      label="Total Amount Sold"
                                      :error-messages="errors[0]"
                                      class="font font-weight-medium text-sm"
                                    />
                                  </ValidationProvider>
                                </div>
                                <div
                                  class="d-flex flex-row flex-grow-1 mx-2 pa-3"
                                  style="background-color: rgb(244, 245, 247); border-radius: 6px;"
                                >
                                  <v-card
                                    v-if="row.changes"
                                    flat
                                    class="d-flex flex-row flex-grow-1 pa-3"
                                  >
                                    <div
                                      class="d-flex flex-column flex-grow-1 "
                                    >
                                      <h3
                                        class="font font-weight-medium font-size-md"
                                      >
                                        Add Damages
                                      </h3>
                                      <div
                                        class="d-flex flex-row flex-grow-1 mt-3"
                                      >
                                        <validationProvider
                                          v-slot="{ errors }"
                                          tag="div"
                                          class="d-flex flex-row flex-grow-1"
                                          rules="numeric"
                                        >
                                          <v-text-field
                                            dense
                                            :error-messages="errors[0]"
                                            class="font font-size-md"
                                            label="Quantity"
                                            v-model="
                                              row.changes.damages.quantity
                                            "
                                            outlined
                                          />
                                        </validationProvider>
                                      </div>
                                      <div
                                        class="d-flex flex-row flex-grow-1 mt-2"
                                      >
                                        <v-textarea
                                          dense
                                          label="Comment"
                                          v-model="row.changes.damages.comment"
                                          outlined
                                          class="font font-size-md text-capitalize"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      class="d-flex flex-column flex-grow-1 ml-2"
                                    >
                                      <div
                                        class="d-flex flex-column flex-grow-1 "
                                      >
                                        <h3
                                          class="font font-weight-medium font-size-md"
                                        >
                                          Add Transfers
                                        </h3>
                                        <div
                                          class="d-flex flex-row flex-grow-1 mt-3"
                                        >
                                          <ValidationProvider
                                            v-slot="{ errors }"
                                            tag="div"
                                            class="d-flex flex-row flex-grow-1"
                                            rules="numeric"
                                          >
                                            <v-text-field
                                              dense
                                              :error-messages="errors[0]"
                                              label="Quantity In"
                                              v-model="
                                                row.changes.transfers.quantityIn
                                              "
                                              class="font mr-2  font-size-md "
                                              outlined
                                            />
                                          </ValidationProvider>
                                          <ValidationProvider
                                            rules="numeric"
                                            tag="div"
                                            class="d-flex flex-row flex-grow-1"
                                            v-slot="{ errors }"
                                          >
                                            <v-text-field
                                              dense
                                              :error-messages="errors[0]"
                                              class="font font-size-md"
                                              label="Quantity Out"
                                              v-model="
                                                row.changes.transfers
                                                  .quantityOut
                                              "
                                              outlined
                                            />
                                          </ValidationProvider>
                                        </div>
                                        <div
                                          class="d-flex flex-row flex-grow-1 mt-2"
                                        >
                                          <v-textarea
                                            dense
                                            label="Comment"
                                            class="font font-weight-md"
                                            v-model="
                                              row.changes.transfers.comment
                                            "
                                            outlined
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </div>
                    </div>
                  </v-row>
                  <RecordLubeSegregations
                    :date="date"
                    :currency="currency"
                    :subLoading="subLoading"
                    :isPageLoading="isPageLoading"
                    :lubeSalesAmount="sum(lineItems, 'amount')"
                    :hasSalesBeenRecorded="hasSalesBeenRecorded"
                    :lubeSaleSegregation="lubeSaleSegregation"
                    @addRevenueRow="addRevenueRow"
                  />
                  <v-row class="mt-5 mr-1">
                    <div
                      class="d-flex flex-row flex-grow-1 flex-fill justify-end"
                    >
                      <v-btn
                        color="primary"
                        :disabled="
                          loading ||
                            hasSalesBeenRecorded ||
                            sum(lineItems, 'amount') <
                              sum(lubeSaleSegregation, 'amount') ||
                            isStockNotTallied ||
                            !hasSomeQuantityBeenSold
                        "
                        :loading="loading"
                        class="font font-size-sm"
                        type="submit"
                        >Save Sales</v-btn
                      >
                    </div>
                  </v-row>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <SnackBar
      :showSnackBar="showSnackBar"
      :status="status"
      :message="message"
    />
    <ConfirmationDialog
      :state="dialog('alert')"
      :loading="loading"
      @save="saveSales"
      @close="close"
    />
  </v-container>
</template>
<script>
import { Api } from "../../api/Api";
import PageTitle from "../../components/PageTitle";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import moment from "moment";
import { timestamp } from "../../filters/timestamp";
import { numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import { currencyFormat } from "../../filters/currencyFormat";
import DialogMixins from "../../mixins/DialogMixins";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "RecordLubeSales",
  components: {
    ValidationProvider,
    PageTitle,
    ValidationObserver,
    SkeletonPreloader: () => import(`../../components/SkeletonPreloader`),
    SnackBar: () => import(`../../components/SnackBar`),
    RecordLubeSegregations: () => import(`./components/RecordLubeSegregations`),
    ConfirmationDialog: () => import(`./components/ConfirmationDialog`)
  },
  mixins: [DialogMixins],
  data() {
    return {
      showSnackBar: false,
      isPageLoading: false,
      hasSalesBeenRecorded: false,
      loading: false,
      isSubLoading: false,
      message: "",
      status: "",
      products: [],
      lineItems: [],
      lubeSaleSegregation: [],
      isStockNotTallied: false,
      hasSomeQuantityBeenSold: false,
      menu: false,
      date: null
    };
  },
  filters: {
    currencyFormat,
    timestamp
  },
  watch: {
    lineItems: {
      handler(items) {
        items.forEach(item => {
          item.amount = isNaN(parseInt(item.quantitySold))
            ? 0
            : parseInt(item.quantitySold) * item.priceToCustomers;
          item.currentStockWithoutTransfers =
            Number(item.tempCurrentStockQuantity) - Number(item.quantitySold);
          item.currentStockQuantity =
            Number(item.tempCurrentStockQuantity) -
            Number(item.quantitySold) +
            Number(item.changes?.transfers?.quantityIn) -
            (Number(item.changes?.transfers?.quantityOut) +
              Number(item.changes.damages?.quantity));
          this.isStockNotTallied = item.currentStockQuantity < 0;
          // check if is quantity sold is greater than 0
          this.hasSomeQuantityBeenSold = items.some(
            item => Number(item.quantitySold) > 0
          );
        });
      },
      deep: true
    },
    date(value) {
      this.getSalesForToday({ date: value });
      this.getLubeCreditSales({ date: value });
    }
  },
  computed: {
    ...mapGetters({
      currency: "currency/getSystemCurrency",
      subLoading: "getIsSubLoading"
    }),
    today() {
      return moment().subtract(1, "day");
    }
  },
  methods: {
    addRevenueRow(payload) {
      this.lubeSaleSegregation.push(payload);
    },
    async getLubesToRecordSales() {
      try {
        this.isPageLoading = true;
        const response = await Api().get(`/products/lubes/list/record/sales`);
        if (response) {
          this.products = response.data.map(product => {
            return {
              name: product.name,
              id: product.id,
              slug: product.slug
            };
          });
          this.lineItems = response.data.map(product => {
            return {
              productId: product.id,
              productName: product?.name,
              quantitySold: 0,
              amount: 0,
              retailCost: Number(product.lubeId[0].retailCost),
              priceToCustomers: Number(product.lubeId[0].sellPriceToCustomers),
              currentStockQuantity: Number(
                product.lubesMonthlySalesId[0]?.currentStockQuantity
              ),
              tempCurrentStockQuantity: Number(
                product.lubesMonthlySalesId[0]?.currentStockQuantity
              ),
              changes: {
                transfers: {
                  quantityIn: 0,
                  quantityOut: 0,
                  comment: ""
                },
                damages: {
                  quantity: 0,
                  comment: ""
                }
              }
            };
          });
          this.isPageLoading = false;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message =
          e.message ?? "Unable to get list of lubes for daily sales";
        this.status = "red";
        this.isPageLoading = false;
      }
    },
    async getSalesForToday({ date }) {
      try {
        this.isPageLoading = true;
        const response = await Api().get(`/sales/daily/${date}/lubes`);
        if (response && response.data.length > 0) {
          this.hasSalesBeenRecorded = true;
          this.isPageLoading = false;
          this.lineItems = response.data.map(product => {
            return {
              productId: product.productId.id,
              productName: product.productId?.name,
              quantitySold: parseInt(product.quantitySold),
              retailCost: product.productId.lubeId[0].retailCost,
              priceToCustomers:
                product.productId.lubeId[0].sellPriceToCustomers,
              amount: product.amount,
              changes: {
                transfers: {
                  quantityIn: product.transfers
                    ? product.transfers.quantityIn
                    : 0,
                  quantityOut: product.transfers
                    ? product.transfers.quantityOut
                    : 0,
                  comment: product.transfers ? product.transfers.comment : ""
                },
                damages: {
                  quantity: product.damages ? product.damages.quantity : 0,
                  comment: product.damages ? product.damages.comment : ""
                }
              }
            };
          });
        } else {
          this.hasSalesBeenRecorded = false;
          this.isPageLoading = false;
          this.getLubesToRecordSales();
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message =
          e.message ?? "Unable to get list of lubes for daily sales";
        this.status = "red";
        this.isPageLoading = false;
      }
    },
    recordDailySales() {
      if (this.lineItems.some(lineItem => lineItem.currentStockQuantity < 0)) {
        const lineItem = this.lineItems.filter(
          lineItem => lineItem.currentStockQuantity < 0
        );
        this.showSnackBar = true;
        this.message = `Some lubes have invalid values provided - ${lineItem
          .map(item => item.productName)
          ?.join(",")}`;
        this.status = "red";
        setTimeout(() => (this.showSnackBar = false), 6000);
        return;
      }
      this.open("alert");
    },
    async saveSales() {
      try {
        this.loading = true;
        let payload = {
          salesDate: this.date,
          lineItems: this.lineItems,
          creditSales: this.lubeSaleSegregation
            ?.map(({ customerName, ...rest }) => {
              return {
                ...rest,
                customerId: customerName?.id,
                customerType:
                  !!customerName.businessRegistrationNumber &&
                  !!customerName.directors
                    ? "business"
                    : "individuals"
              };
            })
            .filter(sale => parseInt(sale.amount) > 0)
        };
        const response = await Api().post(
          `/sales/daily/${this.date}/lubes`,
          payload
        );
        if (response) {
          setTimeout(() => {
            this.showSnackBar = true;
            this.message = "Sales recorded successfully";
            this.status = "success";
            this.loading = false;
            setTimeout(() => (this.showSnackBar = false), 5000);
            this.close("alert");
            this.$router.replace({
              name: "master.sales.list"
            });
          }, 3000);
        }
      } catch (e) {
        this.loading = false;
        this.showSnackBar = true;
        this.message = Array.isArray(e?.response?.data?.message)
          ? e?.response?.data?.message?.join(",")
          : e?.response?.data?.message ?? "Unable to save sales";
        this.status = "red";
        setTimeout(() => (this.showSnackBar = false), 3000);
      }
    },
    sum(items, key) {
      return items.reduce(
        (acc, item) =>
          (acc += isNaN(parseFloat(item[key])) ? 0 : parseFloat(item[key])),
        0
      );
    },
    async getLubeCreditSales({ date }) {
      try {
        this.isSubLoading = true;
        const response = await Api().get(
          `/sales/daily/${date}/lubes/for/customer/credit/sales`
        );
        if (response) {
          this.isSubLoading = false;
          this.lubeSaleSegregation = response.data?.map(sale => {
            return {
              customerName: sale.customerBusinessId
                ? sale.customerBusinessId?.name
                : sale.customerId?.name,
              amount: sale.amount,
              comment: sale.comment
            };
          });
        }
      } catch (e) {
        this.loading = false;
        this.showSnackBar = true;
        this.message =
          e?.response?.data?.message ??
          "Unable to fetch list of credit sales for lubes";
        this.status = "red";
        setTimeout(() => (this.showSnackBar = false), 3000);
      }
    }
  },
  created() {
    this.date = moment()
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    this.getSalesForToday({ date: this.date });

    this.getLubesToRecordSales();
    this.$store.dispatch("currency/getSystemCurrency");
  }
};
</script>
